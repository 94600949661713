import axios from 'axios';

let ip = '';
export const ipEvent = async (): Promise<void> => {
  if (process.browser) {
    if (ip === '') {
      const response = await axios.post('/api/ip', {});
      if (response.status >= 200 && response.status < 300 && response.data.status) {
        ip = response.data.ip;
        window &&
          window.dataLayer &&
          window.dataLayer.push({
            event: 'userIp',
            ipAddress: response.data.ip
          });
      }
    }
  }
};

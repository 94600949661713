function loadScript(src: string, async: boolean): void {
  const script = document.createElement('script');
  script.src = src;
  script.async = async;
  document.body.append(script);
}

export function OntraInit(): void {
  if (process.browser && window) {
    const noPush = new URL(window.location.href).searchParams.get('nopush');
    if (noPush && noPush !== '' && noPush !== 'undefined') {
      return;
    }

    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(function () {
      window.OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID!
      });
    });

    localStorage.setItem('OneSignalAppId', process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID!);
    window.OneSignal.push(function () {
      window.OneSignal.on('subscriptionChange', function (isSubscribed: boolean) {
        if (isSubscribed) {
          window.OneSignal.getUserId()
            .then(function (userId: string) {
              localStorage.setItem('OneSignalUserId', userId);
            })
            .catch(function (x: unknown) {
              console.log('ontraport err', x);
            });
        }
      });
    });

    loadScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', true);
  }
}

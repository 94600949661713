'use client';

import { useEffect, Fragment } from 'react';
import { usePathname } from 'next/navigation';

import { analyticsEvent } from '@/utils/analytics';

const gaMeasurementID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

export const GoogleAnalytics = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (!gaMeasurementID || !window?.location?.href) return;

    analyticsEvent('page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: pathname
    });
  }, [pathname]);

  if (!gaMeasurementID) return null;

  return (
    <Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `   
                             window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                              originalLocation: document.location.protocol + '//' +
                                                document.location.hostname +
                                                document.location.pathname +
                                                document.location.search
                            });`
        }}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `  
                            .async-hide { opacity: 0 !important}
                            `
        }}
      />
    </Fragment>
  );
};

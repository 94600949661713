export type PosthogConfig = {
  distinct_id: string;
  featureFlags: Record<string, string | boolean>;
};

export function getPosthogSearchParamOverrides(searchParams: {
  [key: string]: string | string[] | undefined;
}): PosthogConfig['featureFlags'] {
  const overrideKeys = Object.keys(searchParams).filter(key => key.startsWith('ph-'));

  const overrides: PosthogConfig['featureFlags'] = {};
  overrideKeys.forEach(key => (overrides[key] = String(searchParams[key])));

  return overrides;
}
